import { Locale, type LocalizedString } from "../Locale.js";
import { Service, type ServiceWithPage } from "../Service.js";

export const servicesCategoriesPathMap: Record<ServiceWithPage, LocalizedString> = {
	[Service.Integration]: {
		[Locale.cs]: "integrace",
		[Locale.en]: "integration",
		[Locale.pl]: "integracja",
		[Locale.sk]: "integracia",
	},
	[Service.Service]: {
		[Locale.cs]: "podpora",
		[Locale.en]: "support",
		[Locale.pl]: "konsultacje",
		[Locale.sk]: "poradenstvo",
	},
};

export const servicesPathMap = {
	[Locale.cs]: "sluzby",
	[Locale.en]: "services",
	[Locale.pl]: "uslugi",
	[Locale.sk]: "sluzby",
} satisfies LocalizedString;
